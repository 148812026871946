<template>
  <div class="component-balance-account-report-period-table">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'title', {account: account.title, start_date: startDate, end_date: endDate}) }}</h2>
                </b-col>
                <b-col cols="4" class="text-right">

                  <!-- Toggle result charts -->
                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( translationPath + 'charts.'+showChart)"
                              variant='outline-secondary'
                              class="float-right mr-3 spirecta-toggle-wrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('closing-balance-full-bar-chart')" v-bind:class="{ active: showChart === 'closing-balance-full-bar-chart' }">{{$t( translationPath + 'charts.closing-balance-full-bar-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('closing-balance-value-change-offset-bar-chart')" v-bind:class="{ active: showChart === 'closing-balance-value-change-offset-bar-chart' }">{{$t( translationPath + 'charts.closing-balance-value-change-offset-bar-chart')}}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>

            <div class="col-md-9 pl-0" v-html="$t(translationPath + 'description_period_table_total', {title: account.label})"></div>

            <b-table
              class="spirecta-simple-table mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              foot-clone
              no-footer-sorting
              :items="tableData"
              :fields="tableColumns"
              :busy="!isLoaded"
              :tbodyTrClass="trClass"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- period slot-->
              <template v-slot:cell(period)="row">
                <template v-if="resolution==='month' && row.item.row_type==='data'">
                  <b-link :to="'/transactions/view?account_id='+account.id+'&start_date='+row.item.period_start_date+'&end_date='+row.item.period_end_date">
                    {{ $t('common.months.month' + row.item.period_month + '_long')}}<span v-if="row.item.period_month === '1'">, {{row.item.period_year}}</span>
                  </b-link>
                </template>
                <template v-else-if="row.item.row_type==='total'">
                  {{$t('common.total')}}
                </template>
                <template v-else>
                  <b-link :to="'/transactions/view?account_id='+account.id+'&start_date='+row.item.period_start_date+'&end_date='+row.item.period_end_date">{{row.value}}</b-link>
                </template>
              </template>

              <!-- amounts slot-->
              <template v-slot:cell(period_change)="row">
                <b-link :to="'/transactions/view?account_id='+account.id+'&start_date='+row.item.period_start_date+'&end_date='+row.item.period_end_date"><span :class="row.unformatted < 0 ? 'text-red': ''">{{row.value}}</span></b-link>
              </template>

              <!-- END CUSTOM SLOTS -->

              <!-- TFOOT -->
              <template v-slot:foot(period)>{{ $t('common.total') }}</template>
              <template v-slot:foot(opening_balance) v-if="isLoaded">{{ amountFormatter(apiData.periods.amounts.totals.opening_balance) }}</template>
              <template v-slot:foot(period_change) v-if="isLoaded">{{ amountFormatter(apiData.periods.amounts.totals.period_change) }}</template>
              <template v-slot:foot(closing_balance) v-if="isLoaded">{{ amountFormatter(apiData.periods.amounts.totals.closing_balance) }}</template>

            </b-table>

          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import SpirectaReportTableMixin from '@/views/reports/_inc/SpirectaReportTableMixin'

export default {
  name: 'BalanceAccountReportPeriodTable',
  components: { Loader },
  mixins: [SpirectaReportTableMixin],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    resolution: { type: String, default: null },
    account: {
      type: Object,
      default: () => {
        return {
          id: '',
          title: ''
        }
      }
    },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      showChart: 'closing-balance-full-bar-chart',
      translationPath: 'reports.balance.account.',
      tableData: [],
      tableColumns: [],
      toggleColumnsOptions: {
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    buildTableDataRows () {
      const rows = []
      if (!this.apiData) {
        return rows
      }

      if (this.apiData) {
        const aData = JSON.parse(JSON.stringify(this.apiData.meta.periods))
        aData.reverse()

        aData.map(period => {
          const oRowTmp = {
            period: period,
            opening_balance: this.apiData.periods.amounts.opening_balances[period],
            period_change: this.apiData.periods.amounts.period_changes[period],
            closing_balance: this.apiData.periods.amounts.closing_balances[period],
            period_start_date: this.apiData.meta.periods_meta[period].start_date,
            period_end_date: this.apiData.meta.periods_meta[period].end_date,
            period_year: this.apiData.meta.periods_meta[period].year,
            period_month: this.apiData.meta.periods_meta[period].month,
            row_type: 'data'
          }

          rows.push(oRowTmp)
        })
      }

      return rows
    },
    buildTableColumns () {
      const cols = [
        { key: 'period', label: this.$t(this.translationPath + 'table.period'), sortable: true },
        { key: 'opening_balance', label: this.$t('reports.balance.common.opening_balance'), class: 'text-right', formatter: this.amountFormatter },
        { key: 'period_change', label: this.$t('reports.balance.common.period_change'), class: 'text-right', formatter: this.amountFormatter },
        { key: 'closing_balance', label: this.$t('reports.balance.common.closing_balance'), class: 'text-right', formatter: this.amountFormatter }
      ]

      return cols
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-balance-account-report-period-table{
  tfoot {
    th {
      border-top: 1px solid black;
      border-bottom: 2px solid black;
      padding: 6px 10px;
    }
  }
}
</style>
