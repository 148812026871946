import axios from 'axios'
import moment from 'moment'
import formatAmount from '@/assets/filters/formatAmount'
import useComponentCurrencyInputMixin from '@/mixins/useComponentCurrencyInput'
import Loader from '@/components/common/Loader'

export default {
  components: { Loader },
  mixins: [useComponentCurrencyInputMixin],
  filters: { formatAmount },
  props: {
    meta: {
      type: Object,
      default: () => { return { avgAmount: 0 } }
    }
  },
  data () {
    return {
      showChart: 'simulation-outgoing-balance',
      bIsInSaveProcess: false,
      budgets: [],
      budgetId: 0,
      tabIndexSimulation: 0,
      renderKey: 0,
      presetTitle: '',
      notes: '',
      presets: {},
      selectedPresetId: 0,
      oImportData: {
        textarea: '',
        textAreaValidationClass: ''
      },
      aWithdrawalTypeOptions: [
        { text: this.$t('reports.balance.account.budget_simulation.params.withdrawal_amount_per_month_label'), value: 'amount_per_month' },
        { text: this.$t('reports.balance.account.budget_simulation.params.withdrawal_percentage_per_year_label'), value: 'percentage_per_year' }
      ],
      oPaginationOptions: {
        perYearTable: { iRowsPerPage: 24, iCurrentPage: 1 },
        perMonthTable: { iRowsPerPage: 24, iCurrentPage: 1 }
      },
      aOutputData: { annual_data: [], monthly_data: [] },
      aLastExcelParseErrors: [],
      tabIndexData: 0,
      isLoadingTable: false
    }
  },
  computed: {
    budgetTitle () {
      let title = ''
      for (const i in this.budgets) {
        if (this.budgets[i].id === this.budgetId) {
          title = this.budgets[i].title
          break
        }
      }
      return title
    },
    presetOptions () {
      const opts = []
      for (const i in this.presets) {
        opts.push({ value: this.presets[i].id, text: this.presets[i].title })
      }
      opts.push({ value: 'new', text: '-- ' + this.$t(this.translationPath + 'params.preset_options.new_preset') })
      opts.push({ value: 'saved', text: '-- ' + this.$t(this.translationPath + 'params.preset_options.saved_data') })

      return opts
    },
    endYearmonthInvalidFeedback () {
      if (this.$v.oSimulationParams.end_yearmonth.required === false) return this.$t(this.translationPath + 'errors.required')
      if (this.$v.oSimulationParams.end_yearmonth.isDateValid === false) return this.$t(this.translationPath + 'errors.date_invalid')
      return ''
    },
    phase1StartYearMonthInvalidFeedback () {
      if (this.$v.oSimulationParams.phase1.start_yearmonth.required === false) return this.$t(this.translationPath + 'errors.required')
      if (this.$v.oSimulationParams.phase1.start_yearmonth.isDateValid === false) return this.$t(this.translationPath + 'errors.date_invalid')
      return ''
    },
    phase2StartYearMonthInvalidFeedback () {
      if (this.$v.oSimulationParams.phase2.start_yearmonth.required === false) return this.$t(this.translationPath + 'errors.required')
      if (this.$v.oSimulationParams.phase2.start_yearmonth.isDateValid === false) return this.$t(this.translationPath + 'errors.date_invalid')
      return ''
    },
    phase3StartYearMonthInvalidFeedback () {
      if (this.$v.oSimulationParams.phase3.start_yearmonth.required === false) return this.$t(this.translationPath + 'errors.required')
      if (this.$v.oSimulationParams.phase3.start_yearmonth.isDateValid === false) return this.$t(this.translationPath + 'errors.date_invalid')
      return ''
    },
    phase1AmountPerMonthInvalidFeedback () {
      if (this.$v.oSimulationParams.phase1.amountPerMonth.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    phase2AmountPerMonthInvalidFeedback () {
      if (this.$v.oSimulationParams.phase2.amountPerMonth.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    phase3AmountPerMonthInvalidFeedback () {
      if (this.$v.oSimulationParams.phase3.amountPerMonth.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    presetTitleInvalidFeedback () {
      if (this.$v.presetTitle.required === false) return this.$t(this.translationPath + 'errors.required')
      if (this.$v.presetTitle.minLength === false) return this.$t(this.translationPath + 'errors.min_length_2')
      if (this.$v.presetTitle.maxLength === false) return this.$t(this.translationPath + 'errors.max_length_100')
      return ''
    }
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('simulation-chart-to-display', { chart: showChart })
    },
    startOfMonthFromYearMonthString (sYearMonth) {
      return moment(sYearMonth + '-15').startOf('month').format('YYYY-MM-DD')
    },
    endOfMonthFromYearMonthString (sYearMonth) {
      return moment(sYearMonth + '-15').endOf('month').format('YYYY-MM-DD')
    },
    subtractOneMonthFromYearMonthString (sYearMonth) {
      return moment(sYearMonth + '-15').subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    },
    withdrawalLabel () {
      switch (this.oSimulationParams.withdrawal_type) {
        case 'amount_per_month' :
          return { prefix: '', suffix: ' ' + this.currentCOA.currency_iso + ' ' + this.$t(this.translationPath + 'params.per_month') }
        case 'percentage_per_year' :
          return { prefix: '', suffix: ' % ' + this.$t(this.translationPath + 'params.per_year') }
      }
    },
    selectPreset (presetId) {
      if (Number.isInteger(presetId)) {
        this.selectedPresetId = presetId
        for (const i in this.presets) {
          if (Number(this.presets[i].id) === presetId) {
            this.oSimulationParams = this.presets[i].value
            this.presetTitle = this.presets[i].title
            this.resetForms()
            this.rerenderCurrencyInputs()
            break
          }
        }
      } else if (presetId === 'new') {
        this.presetTitle = ''
      } else if (presetId === 'saved') {
        this.presetTitle = ''
        this.loadSavedData()
      }
    },
    resetForms () {
      this.$v.oSimulationParams.$reset()
      this.$v.presetTitle.$reset()
    },
    onBudgetChange (budgetId) {
      if (budgetId === this.budgetId) {
        return false
      }

      this.$router.push({
        query: {
          start_date: this.$route.query.start_date,
          end_date: this.$route.query.end_date,
          budget: budgetId
        }
      })

      this.budgetId = budgetId
      this.loadSavedData(true)
    },
    async loadBudgets () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/budgets`)
          .then(response => {
            this.budgets = response.data.data
            if (this.budgetId && !this.aOutputData.monthly_data.length) {
              this.loadSavedData(false)
            }
            resolve(this.budgets)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    async loadPresets () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/budget-simulated/presets`)
          .then(response => {
            this.presets = response.data.data
            this.presets.map(item => {
              item.title = item.name.substr(25)
              item.value = JSON.parse(item.value)
            })
            resolve(true)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    loadSavedData (bShowModalOnNoData = true) {
      this.aOutputData = { annual_data: [], monthly_data: [] }
      Promise.all([this.loadSavedTabledata(bShowModalOnNoData), this.loadSavedFormData()])
        .then(() => {
          this.$emit('simulation-data', { data: this.aOutputData })
          this.resetForms()
        })
        .catch(err => {
          console.error(err)
        })
    },
    async loadSavedTabledata (bShowModalOnNoData = true) {
      return new Promise((resolve, reject) => {
        this.isLoadingTable = true
        axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/budget-simulated/${this.budgetId}/${this.$route.params.account_id}`)
          .then(response => {
            if (!response.data.data.length && bShowModalOnNoData) {
              this.$refs.NoSavedDataModal.show()
            } else if (response.data.data.length) {
              const annualDataTmp = {}

              let firstDate = '19001'
              let lastDate = '99999912'
              if (response.data.data.length) {
                const firstElement = response.data.data[0]
                const lastElement = response.data.data[response.data.data.length - 1]
                firstDate = firstElement.year.toString() + firstElement.month.toString()
                lastDate = lastElement.year.toString() + lastElement.month.toString()
              }

              response.data.data.map(item => {
                if (!annualDataTmp[item.year]) {
                  annualDataTmp[item.year] = {
                    year: item.year,
                    incoming_balance: 0,
                    added_amount: 0,
                    total_value_change: 0,
                    increase: 0,
                    value_before_tax: 0,
                    tax_amount: 0,
                    tax: 0,
                    amount: 0,
                    withdrawal: 0,
                    outgoing_balance: 0,
                    total: 0,
                    interest: 0,
                    amortization: 0,
                    months: []
                  }
                }

                const monthData = this.translateDbItemToMonthData(item)

                this.aOutputData.monthly_data.push(monthData)
                annualDataTmp[item.year].months.push(monthData)

                const dateLabel = monthData.year.toString() + monthData.month.toString()
                if (monthData.month === 1 || firstDate === dateLabel) {
                  annualDataTmp[item.year].incoming_balance = monthData.incoming_balance
                }

                annualDataTmp[item.year].added_amount += monthData.added_amount
                annualDataTmp[item.year].amount += monthData.added_amount
                annualDataTmp[item.year].total_value_change += monthData.total_value_change
                annualDataTmp[item.year].increase += monthData.total_value_change
                annualDataTmp[item.year].tax_amount += monthData.tax_amount
                annualDataTmp[item.year].tax += monthData.tax_amount
                annualDataTmp[item.year].withdrawal += monthData.withdrawal
                annualDataTmp[item.year].total += monthData.total
                annualDataTmp[item.year].interest += monthData.interest
                annualDataTmp[item.year].amortization += monthData.amortization

                if (monthData.month === 12 || lastDate === dateLabel) {
                  annualDataTmp[item.year].outgoing_balance = monthData.outgoing_balance
                  annualDataTmp[item.year].value_before_tax = monthData.value_before_tax
                }
              })

              this.aOutputData.annual_data = Object.values(annualDataTmp)
              this.resetForms()
            }

            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            this.isLoadingTable = false
          })
      })
    },
    async loadSavedFormData () {
      return new Promise((resolve, reject) => {
        const apiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/budget-option/${this.budgetId}/${this.$route.params.account_id}/simulation_form`
        axios.get(apiEndpoint)
          .then(response => {
            if (response.data.data.simulation_form) {
              this.oSimulationParams = JSON.parse(response.data.data.simulation_form.value)
              this.presetTitle = ''
              this.selectedPresetId = 'saved'
            }
            this.resetForms()
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            this.rerenderCurrencyInputs()
          })
      })
    },
    async savePreset () {
      this.$v.presetTitle.$touch()
      if (this.$v.presetTitle.$invalid) {
        return false
      }

      return new Promise((resolve, reject) => {
        const putData = { options: {} }
        putData.options['budget_simulation_preset_' + this.presetTitle] = JSON.stringify(this.oSimulationParams)
        axios.put(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/options`, putData)
          .then(response => {
            this.selectedPresetId = response.data.data[0].id
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.save_preset_success').toString(), {
              title: this.$t(this.translationPath + 'toast.save_preset_title').toString(),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
            this.$v.presetTitle.$reset()
            this.loadPresets()
            resolve(true)
          })
          .catch(err => {
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.save_preset_fail').toString(), {
              title: this.$t(this.translationPath + 'toast.save_preset_title').toString(),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            })
            reject(err)
          })
      })
    },
    identifyPhase (iYear, iMonth) {
      const sPhaseDate = iYear + '-' + (iMonth + 1) + '-15'
      if (moment(sPhaseDate, 'YYYY-MM-DD').isBefore(moment(this.oSimulationParams.phase2.start_yearmonth + '-15', 'YYYY-MM-DD'))) {
        return 'phase1'
      } else if (moment(sPhaseDate, 'YYYY-MM-DD').isBetween(moment(this.oSimulationParams.phase2.start_yearmonth + '-01', 'YYYY-MM-DD'), moment(this.oSimulationParams.phase3.start_yearmonth + '-01', 'YYYY-MM-DD'))) {
        return 'phase2'
      } else {
        return 'phase3'
      }
    },
    amountFormatter (value) {
      const fValue = Object.is(value, -0) ? 0 : value
      return formatAmount(fValue, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, false, 0)
    },
    saveMonthlyData () {
      if (this.bIsInSaveProcess) {
        return false
      }

      this.bIsInSaveProcess = true
      Promise.all([this.saveMonthlyTable(), this.saveFormData()])
        .then(() => {
          this.presetTitle = ''
          this.selectedPresetId = 'saved'
          this.bIsInSaveProcess = false
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.save_data_success').toString(), {
            title: this.$t(this.translationPath + 'toast.save_data_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.save_data_fail').toString(), {
            title: this.$t(this.translationPath + 'toast.save_data_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    async saveMonthlyTable () {
      return new Promise((resolve, reject) => {
        const postData = {
          data: [],
          predelete_data: {
            account_id: this.account.id,
            budget_id: this.budgetId
          }
        }

        let openingBalance = 0
        this.aOutputData.monthly_data.map(item => {
          postData.data.push(this.translateParamsToDb(item, openingBalance))
          openingBalance += item.total
        })

        axios.post(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/mass-store-update`, postData)
          .then(response => {
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async saveFormData () {
      return new Promise((resolve, reject) => {
        const apiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/budget-option/${this.budgetId}/${this.$route.params.account_id}/simulation_form`
        axios.put(apiEndpoint, { option_value: JSON.stringify(this.oSimulationParams) })
          .then(response => {
            this.$v.oSimulationParams.$reset()
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    parsePastedValues () {
      const aPostData = {}
      aPostData.import_data = this.oImportData.textarea
      aPostData.account_type = this.account.type

      axios.post(`${process.env.VUE_APP_ROOT_API}/reports/balance/accounts/parse-simulation-data`, aPostData)
        .then(response => {
          const aParsedData = response.data.data

          if (aParsedData.errors.length === 0) {
            this.aOutputData.annual_data = aParsedData.annual_data
            this.aOutputData.monthly_data = aParsedData.monthly_data

            this.$emit('simulation-data', { data: this.aOutputData })
          } else {
            this.aLastExcelParseErrors = aParsedData.errors
            this.oImportData.textAreaValidationClass = 'is-invalid'
            this.$refs.ExcelParseErrorModal.show()
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    async updateNotes () {
      return new Promise((resolve, reject) => {
        const apiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/budget-option/${this.budgetId}/${this.$route.params.account_id}/simulation_notes`
        axios.put(apiEndpoint, { option_value: this.notes })
          .then(response => {
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.update_notes_success').toString(), {
              title: this.$t(this.translationPath + 'toast.update_notes_title').toString(),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
            resolve(true)
          })
          .catch(err => {
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.update_notes_fail').toString(), {
              title: this.$t(this.translationPath + 'toast.update_notes_title').toString(),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            })
            reject(err)
          })
      })
    },
    async loadNotes () {
      return new Promise((resolve, reject) => {
        if (!this.budgetId || !this.$route.params.account_id) {
          resolve(true)
        } else {
          const apiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/budget-option/${this.budgetId}/${this.$route.params.account_id}/simulation_notes`
          axios.get(apiEndpoint)
            .then(response => {
              if (response.data.data.simulation_notes) {
                this.notes = response.data.data.simulation_notes.value
              } else {
                this.notes = ''
              }
            })
        }
      })
    },
    translateDbItemToMonthData (item) {
      const data = {
        year: item.year,
        month: item.month,
        incoming_balance: item.opening_balance,
        added_amount: item.deposit,
        amount: item.deposit,
        amortization: item.deposit,
        total_value_change: item.change_in_value,
        increase: item.change_in_value,
        value_before_tax: item.value_before_tax,
        tax_amount: item.tax,
        tax: item.tax,
        withdrawal: item.withdrawal,
        interest: item.interest,
        outgoing_balance: Number(item.amount),
        total: item.value_before_tax - item.tax
      }

      return data
    },
    translateParamsToDb (sourceData, openingBalance = 0) {
      const targetData = {
        budget_id: this.budgetId,
        account_id: this.account.id,
        year: sourceData.year,
        month: sourceData.month,
        amount: 0,
        opening_balance: openingBalance,
        deposit: null,
        change_in_value: null,
        value_before_tax: null,
        tax: null,
        withdrawal: null
      }

      switch (this.account.type) {
        case 'income':
          targetData.deposit = sourceData.amount
          targetData.change_in_value = sourceData.increase
          targetData.value_before_tax = sourceData.total + sourceData.tax
          targetData.tax = sourceData.tax
          targetData.withdrawal = 0
          targetData.amount = sourceData.total
          break
        case 'expense':
          targetData.deposit = sourceData.amount
          targetData.change_in_value = sourceData.increase
          targetData.value_before_tax = sourceData.total
          targetData.tax = 0
          targetData.withdrawal = 0
          targetData.amount = sourceData.total
          break
        case 'asset':
          targetData.opening_balance = sourceData.incoming_balance
          targetData.amount = sourceData.outgoing_balance
          targetData.deposit = sourceData.added_amount
          targetData.change_in_value = sourceData.total_value_change
          targetData.value_before_tax = sourceData.value_before_tax
          targetData.tax = sourceData.tax_amount
          targetData.withdrawal = sourceData.withdrawal
          break
        case 'liability':
          targetData.opening_balance = sourceData.incoming_balance
          targetData.deposit = sourceData.amortization
          targetData.interest = sourceData.interest
          targetData.change_in_value = sourceData.incoming_balance - sourceData.amortization
          targetData.value_before_tax = sourceData.incoming_balance - sourceData.amortization
          targetData.amount = Math.abs(sourceData.outgoing_balance)
          break
      }

      return targetData
    },
    setupFormDefaultsOnce () {
      if (this.oSimulationParams.end_yearmonth) {
        return false
      }

      if (this.currentCOA.default_simulation_end_date) {
        this.oSimulationParams.end_yearmonth = this.currentCOA.default_simulation_end_date
      } else if (this.currentCOA.id && !this.currentCOA.default_simulation_end_date) {
        this.oSimulationParams.end_yearmonth = moment().add(40, 'years').format('YYYY-MM')
      }
    }
  },
  watch: {
    'currentCOA.default_budget_id' () {
      if (!this.budgetId) {
        this.budgetId = this.currentCOA.default_budget_id
      }
      this.setupFormDefaultsOnce()
    },
    'currentCOA.default_simulation_end_date' () {
      this.setupFormDefaultsOnce()
    },
    budgetId () {
      this.loadNotes()
    },
    account: {
      deep: true,
      handler () {
        this.loadNotes()
      }
    }
  }
}
