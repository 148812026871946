<template>
  <b-container fluid class="component-balance-account-report-simulation-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'component-balance-account-report-simulation-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'BudgetSimulationChart',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    simulationData: { type: Object },
    account: { type: Object },
    currencySymbol: { type: String },
    chartType: { type: String, default: 'simulation-outgoing-balance' },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.account.budget_simulation.charts.'
    }
  },
  created () {
    console.log('---------------------------')
  },
  methods: {
    fillChart () {
      if (!this.simulationData) {
        return false
      }

      switch (this.chartType) {
        case 'simulation-outgoing-balance':
          this.createSimulationChart('outgoing_balance', 'simulation-outgoing-balance')
          break
        case 'simulation-withdrawal-per-year':
          this.createSimulationChart('withdrawal', 'simulation-withdrawal-per-year')
          break
        case 'simulation-withdrawal-acc':
          this.createSimulationChartAccumulated('withdrawal', 'simulation-withdrawal-acc')
          break
        case 'simulation-added-amounts':
          this.createSimulationChart('added_amount', 'simulation-added-amounts')
          break
        case 'simulation-added-amounts-acc':
          this.createSimulationChartAccumulated('added_amount', 'simulation-added-amounts-acc')
          break
        case 'simulation-performance-total-amount': {
          const color = this.account.type === 'income' ? 'green' : 'red'
          this.createSimulationChart('total', 'simulation-performance-total-amount', color)
          break
        }
        case 'simulation-amortization':
          this.createSimulationChart('amortization', 'simulation-amortization', 'green')
          break
        case 'simulation-interest':
          this.createSimulationChart('interest', 'simulation-interest', 'yellow')
          break
      }
    },
    createSimulationChart (sParam, sChartLabel, sColor = 'green') {
      const sLabel = this.$t(this.translationPath + sChartLabel)
      const dataset = { type: 'bar', data: [], label: sLabel }
      const labels = []
      const aBackgroundColors = []
      const suffix = ' ' + this.currencySymbol

      for (const period in this.simulationData.annual_data) {
        const oPeriod = this.simulationData.annual_data[period]
        labels.push(oPeriod.year)
        dataset.data.push(oPeriod[sParam])
        if (sColor === 'green') {
          aBackgroundColors.push(oPeriod[sParam] < 0 ? this.getRed() : this.getGreen())
        } else if (sColor === 'yellow') {
          aBackgroundColors.push(this.getYellow())
        } else if (sColor === 'red') {
          aBackgroundColors.push(this.getRed())
        }
      }

      this.chartOptions.legend.display = true
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return sLabel + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      dataset.backgroundColor = aBackgroundColors

      this.chartData = {
        labels: labels,
        datasets: [dataset]
      }
    },
    createSimulationChartAccumulated (sParam, sChartLabel) {
      const sLabel = this.$t(this.translationPath + sChartLabel)
      const dataset = { type: 'bar', data: [], label: sLabel }
      const labels = []
      const aBackgroundColors = []
      const suffix = ' ' + this.currencySymbol
      let iAccumulatedSum = 0

      for (const period in this.simulationData.annual_data) {
        const oPeriod = this.simulationData.annual_data[period]
        labels.push(oPeriod.year)
        iAccumulatedSum += oPeriod[sParam]
        dataset.data.push(iAccumulatedSum)
        aBackgroundColors.push(oPeriod[sParam] < 0 ? this.getRed() : this.getGreen())
      }

      this.chartOptions.legend.display = true
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return sLabel + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      dataset.backgroundColor = aBackgroundColors

      this.chartData = {
        labels: labels,
        datasets: [dataset]
      }
    }
  },
  watch: {
    simulationData: {
      handler () {
        this.fillChart()
      },
      immediate: true,
      deep: true
    },
    chartType: {
      handler () {
        this.fillChart()
      },
      immediate: true
    }
  }
}
</script>
