<template>
  <div class="component-performance-account-report-budget-simulation-view">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="12" md="3" lg="4" xl="6">
                  <h2 v-if="isLoaded">{{ $t(translationPath + 'settings_title_' + account.type, { account_name: account.title }) }}</h2>
                </b-col>
                <b-col class="text-right" cols="12" md="9" lg="8" xl="6">
                  <!-- Toggle simulation charts -->
                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( translationPath + 'charts.' + showChart)"
                              variant='outline-secondary'
                              class="float-right mr-3 mb-2 spirecta-toggle-wrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('simulation-performance-total-amount')" v-bind:class="{ active: showChart === 'simulation-performance-total-amount' }">{{$t( translationPath + 'charts.simulation-performance-total-amount')}}</b-dropdown-item>
                  </b-dropdown>
                  <toggle-budgets-dropdown ref="BudgetDropdown" :budgets="budgets" :initial-budget-id="budgetId" @change="onBudgetChange" />
                </b-col>
              </b-row>
            </template>

            <b-row no-gutters>
              <b-col cols="8">
                <div v-html="$t(translationPath + 'settings_description')" class="pt-2 pb-3"></div>
              </b-col>
            </b-row>

            <b-row no-gutters>
              <b-col cols="12">
                <b-card no-body class="white-tabs-card">
                  <b-tabs class="white-tabs" v-model="tabIndexSimulation">
                    <b-tab :title="$t(translationPath + 'tab_simulate')">

                      <!-- START: SIMULATE FORM -->
                      <b-form class="pt-4" v-if="isLoaded">
                        <!-- years -->
                        <b-form-group
                          label-for="end_yearmonth"
                          label-cols-lg="3"
                          content-cols-lg="3"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.end_yearmonth')"
                          :description="$t(translationPath + 'params.period_years_description')"
                          :invalid-feedback="endYearmonthInvalidFeedback"
                        >
                          <b-form-input
                            id="start_year_phase1"
                            v-model="oSimulationParams.end_yearmonth"
                            class="form-control text-left text-md-right"
                            :state="$v.oSimulationParams.end_yearmonth.$dirty ? !$v.oSimulationParams.end_yearmonth.$invalid : null"
                          />
                        </b-form-group>

                        <b-row no-gutters>
                          <b-col cols="3"></b-col>
                          <b-col cols="3" class="px-2 pb-2">{{$t(translationPath + 'params.phase01')}}</b-col>
                          <b-col cols="3" class="px-2 pb-2">{{$t(translationPath + 'params.phase02')}}</b-col>
                          <b-col cols="3" class="px-2 pb-2">{{$t(translationPath + 'params.phase03')}}</b-col>
                        </b-row>

                        <!-- *************************************************
                              Period years
                        ************************************************* -->
                        <b-row no-gutters>
                          <b-col cols="3" class="px-2">
                            {{$t(translationPath + 'params.start_yearmonth')}}
                          </b-col>
                          <b-col cols="3">
                            <b-form-group
                              label-for="start_year_phase1"
                              label-class="pr-4 text-black"
                              :description="$t(translationPath + 'params.start_yearmonth_description', {start_period: startOfMonthFromYearMonthString(oSimulationParams.phase1.start_yearmonth), end_period: subtractOneMonthFromYearMonthString(oSimulationParams.phase2.start_yearmonth)})"
                              :invalid-feedback="phase1StartYearMonthInvalidFeedback"
                            >
                              <b-form-input
                                id="start_year_phase1"
                                v-model="oSimulationParams.phase1.start_yearmonth"
                                class="form-control text-left"
                                :state="$v.oSimulationParams.phase1.start_yearmonth.$dirty ? !$v.oSimulationParams.phase1.start_yearmonth.$invalid : null"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="start_year_phase2"
                              label-class="pr-4 text-black"
                              :description="$t(translationPath + 'params.start_yearmonth_description', {start_period: startOfMonthFromYearMonthString(oSimulationParams.phase2.start_yearmonth), end_period: subtractOneMonthFromYearMonthString(oSimulationParams.phase3.start_yearmonth)})"
                              :invalid-feedback="phase2StartYearMonthInvalidFeedback"
                            >
                              <b-form-input
                                id="start_year_phase1"
                                v-model="oSimulationParams.phase2.start_yearmonth"
                                class="form-control text-left"
                                :state="$v.oSimulationParams.phase2.start_yearmonth.$dirty ? !$v.oSimulationParams.phase2.start_yearmonth.$invalid : null"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="start_year_phase2"
                              label-class="pr-4 text-black"
                              :description="$t(translationPath + 'params.start_yearmonth_description', {start_period: startOfMonthFromYearMonthString(oSimulationParams.phase3.start_yearmonth), end_period: endOfMonthFromYearMonthString(oSimulationParams.end_yearmonth)})"
                              :invalid-feedback="phase3StartYearMonthInvalidFeedback"
                            >
                              <b-form-input
                                id="start_year_phase1"
                                v-model="oSimulationParams.phase3.start_yearmonth"
                                class="form-control text-left"
                                :state="$v.oSimulationParams.phase3.start_yearmonth.$dirty ? !$v.oSimulationParams.phase3.start_yearmonth.$invalid : null"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <!-- *************************************************
                              Amount per month
                        ************************************************* -->
                        <b-row no-gutters>
                          <b-col cols="3" class="px-2">
                            {{ $t(translationPath + 'params.amount_per_month_' + account.type) }}
                          </b-col>
                          <b-col cols="3">
                            <b-form-group
                              label-for="amount_per_month_phase1"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase1AmountPerMonthInvalidFeedback"
                            >
                              <currency-input
                                ref="CurrencyInputPhase1AmountPerMonth"
                                id="amount_per_month_phase1"
                                v-model="oSimulationParams.phase1.amountPerMonth"
                                :input-class="inputValidationClasses.phase1.amountPerMonth"
                                :precision="0"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="amount_per_month_phase2"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase2AmountPerMonthInvalidFeedback"
                            >
                              <currency-input
                                ref="CurrencyInputPhase2AmountPerMonth"
                                id="amount_per_month_phase2"
                                v-model="oSimulationParams.phase2.amountPerMonth"
                                :input-class="inputValidationClasses.phase2.amountPerMonth"
                                :precision="0"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="amount_per_month_phase3"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase3AmountPerMonthInvalidFeedback"
                            >
                              <currency-input
                                ref="CurrencyInputPhase3AmountPerMonth"
                                id="amount_per_month_phase3"
                                v-model="oSimulationParams.phase3.amountPerMonth"
                                :input-class="inputValidationClasses.phase3.amountPerMonth"
                                :precision="0"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <!-- *************************************************
                              Annual increase
                        ************************************************* -->
                        <b-row no-gutters>
                          <b-col cols="3" class="px-2">
                            {{$t(translationPath + 'params.annual_increase')}}
                          </b-col>
                          <b-col cols="3">
                            <b-form-group
                              label-for="amount_per_month_phase1"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase1AnnualIncreaseInvalidFeedback"
                            >
                              <percentage-input
                                id="expected_annual_return_phase1"
                                v-model="oSimulationParams.phase1.annualIncrease"
                                :class="inputValidationClasses.phase1.annualIncrease"
                                :precision="2"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="amount_per_month_phase2"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase2AnnualIncreaseInvalidFeedback"
                            >
                              <percentage-input
                                id="expected_annual_return_phase2"
                                v-model="oSimulationParams.phase2.annualIncrease"
                                :class="inputValidationClasses.phase2.annualIncrease"
                                :precision="2"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="amount_per_month_phase3"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase3AnnualIncreaseInvalidFeedback"
                            >
                              <percentage-input
                                id="expected_annual_return_phase3"
                                v-model="oSimulationParams.phase3.annualIncrease"
                                :class="inputValidationClasses.phase3.annualIncrease"
                                :precision="2"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <!-- *************************************************
                              Tax
                        ************************************************* -->
                        <b-row no-gutters v-if="account.type === 'income'">
                          <b-col cols="3" class="px-2">
                            {{ $t(translationPath + 'params.tax') }}
                          </b-col>
                          <b-col cols="3">
                            <b-form-group
                              label-for="tax"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase1TaxInvalidFeedback"
                            >
                              <percentage-input
                                id="phase1_tax"
                                v-model="oSimulationParams.phase1.tax"
                                :input-class="inputValidationClasses.phase1.tax"
                                :precision="2"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="tax"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase2TaxInvalidFeedback"
                            >
                              <percentage-input
                                id="phase2_tax"
                                v-model="oSimulationParams.phase2.tax"
                                :input-class="inputValidationClasses.phase2.tax"
                                :precision="2"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="tax"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase3TaxInvalidFeedback"
                            >
                              <percentage-input
                                id="phase3_tax"
                                v-model="oSimulationParams.phase3.tax"
                                :input-class="inputValidationClasses.phase3.tax"
                                :precision="2"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <!-- PRESETS -->
                        <b-form-group
                          label-for=""
                          label-cols-lg="3"
                          content-cols-lg="9"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.presets')"
                        >
                          <b-row no-gutters>
                            <b-col cols="12" lg="4" class="pr-1">
                              <b-form-select
                                id="presets"
                                class="form-control text-left"
                                v-model="selectedPresetId"
                                :options="presetOptions"
                                @change="selectPreset"
                              />
                            </b-col>
                            <b-col cols="12" lg="8" v-if="Number.isInteger(this.selectedPresetId)">
                              <button class="btn btn-sm btn-primary mt-2 ml-lg-2" @click.prevent="savePreset">{{ $t('common.save' )}}</button>
                            </b-col>
                          </b-row>
                        </b-form-group>

                        <template v-if="this.selectedPresetId === 'new'">
                          <b-form-group
                            label-for="preset_title"
                            label-cols-lg="3"
                            content-cols-lg="9"
                            label-class="pr-4 text-black px-2"
                            :label="$t(translationPath + 'lbl_save_preset_as')"
                            :invalid-feedback="presetTitleInvalidFeedback"
                          >
                            <b-row no-gutters>
                              <b-col cols="12" lg="4" class="pr-1">
                                <b-form-input
                                  id="preset_title"
                                  class="form-control"
                                  v-model="presetTitle"
                                  :state="$v.presetTitle.$dirty ? !$v.presetTitle.$invalid : null"
                                />
                              </b-col>
                              <b-col cols="12" lg="8">
                                <button class="btn btn-sm btn-primary mt-2 ml-lg-2" @click.prevent="savePreset">{{ $t('common.save' )}}</button>
                              </b-col>
                            </b-row>
                          </b-form-group>
                        </template>

                        <!-- SIMULATE AND SAVE DATA BTNs -->
                        <b-row no-gutters>
                          <b-col cols="12" class="text-left pt-2">
                            <button class="btn btn-sm btn-primary mr-2" @click.prevent="simulate" prev>{{$t(translationPath + 'params.action_simulate')}}</button>
                            <button v-if="budgets.length && aOutputData.monthly_data.length" class="btn btn-sm btn-primary" @click.prevent="saveMonthlyData" prev>{{$t(translationPath + 'params.action_save_monthly_data')}}</button>
                          </b-col>
                        </b-row>

                      </b-form>
                      <!-- END: SIMULATE FORM -->
                    </b-tab>
                    <b-tab :title="$t(translationPath + 'tab_upload_csv')">

                      <b-row no-gutters>
                        <b-col cols="8">
                          <div v-html="$t(translationPath + 'tab_upload_csv_description')" class="pt-2"></div>
                        </b-col>
                      </b-row>

                      <b-form>
                        <b-form-group
                          label-for="import_textarea"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.import_textarea')"
                        >
                          <b-form-textarea
                            id="import_textarea"
                            v-model="oImportData.textarea"
                            class="form-control text-left"
                            :class="oImportData.textAreaValidationClass"
                            :placeholder="$t(translationPath + 'params.import_' + account.type + '_textarea_placeholder')"
                            @input="oImportData.textAreaValidationClass = ''"
                            >
                          </b-form-textarea>
                        </b-form-group>
                      </b-form>

                      <!-- PARSE BTN -->
                      <b-row no-gutters>
                        <b-col cols="3" class="text-left">
                          <button class="btn btn-sm btn-primary" @click.prevent="parsePastedValues" prev>{{$t(translationPath + 'params.action_import')}}</button>
                        </b-col>
                      </b-row>

                    </b-tab>

                    <b-tab :title="$t(translationPath + 'tab_notes')">
                      <b-row no-gutters>
                        <b-col cols="8">
                          <div v-html="$t(translationPath + 'tab_notes_description')" class="pt-2 mb-3"></div>
                        </b-col>
                      </b-row>

                      <b-form>
                        <b-form-group
                          label-for="import_textarea"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.notes')"
                        >
                          <b-form-textarea
                            id="import_textarea"
                            v-model="notes"
                            class="form-control text-left"
                          />
                        </b-form-group>
                      </b-form>

                      <b-row no-gutters>
                        <b-col cols="3" class="text-left">
                          <button class="btn btn-sm btn-primary" @click.prevent="updateNotes" prev>{{$t(translationPath + 'params.action_update_notes')}}</button>
                        </b-col>
                      </b-row>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>
            </b-row>
          </b-card>

          <b-card class="main-gradient-content-card borderlight mt-3 pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'simulation_table_title', { budget: budgetTitle }) }}</h2>
                </b-col>
                <b-col cols="4" class="text-right">
                  <button class="btn btn-sm btn-outline-secondary" @click.prevent="exportMonthlyValues()">{{$t(translationPath + 'export_transactions' )}}</button>
                </b-col>
              </b-row>

              <b-row no-gutters>
                <b-col cols="8">
                  <div v-html="$t(translationPath + 'simulation_table_description')" class="pt-2"></div>
                </b-col>
              </b-row>

              <b-row no-gutters>
                <b-col cols="12">
                  <b-card no-body class="white-tabs-card">
                    <b-tabs class="white-tabs" v-model="tabIndexData">
                      <b-tab :title="$t(translationPath + 'annual_data')">
                        <b-table
                          class="spirecta-simple-table simulation-result-table mb-0"
                          show-empty
                          hover
                          responsive
                          striped
                          stacked="md"
                          :items="aOutputData.annual_data"
                          :fields="tableColumns(account.type, 'annual')"
                          :busy="!isLoaded || isLoadingTable"
                        >
                          <template v-slot:table-busy>
                            <loader />
                          </template>
                        </b-table>
                      </b-tab>
                      <b-tab :title="$t(translationPath + 'monthly_data')">
                        <b-table
                          class="spirecta-simple-table simulation-result-table mb-0"
                          show-empty
                          hover
                          responsive
                          striped
                          stacked="md"
                          :items="aOutputData.monthly_data"
                          :fields="tableColumns(account.type, 'monthly')"
                          :busy="!isLoaded || isLoadingTable"
                          :per-page="oPaginationOptions.perMonthTable.iRowsPerPage"
                          :current-page="oPaginationOptions.perMonthTable.iCurrentPage"
                          :key="renderKey"
                        >
                          <template v-slot:table-busy>
                            <loader />
                          </template>
                        </b-table>
                        <b-pagination
                          v-model="oPaginationOptions.perMonthTable.iCurrentPage"
                          :total-rows="aOutputData.monthly_data.length"
                          :per-page="oPaginationOptions.perMonthTable.iRowsPerPage"
                          aria-controls="my-table"
                        ></b-pagination>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <info-modal
      ref="NoSavedDataModal"
      :title="$t(translationPath + 'no_saved_data_modal_title')"
      :message="$t(translationPath + 'no_saved_data_modal_text', { account_title: account.title, budget_title: budgetTitle })"
      variant="warning"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import ToggleBudgetsDropdown from '@/components/common/ToggleBudgetsDropdown'
import BudgetSimulationMixin from '@/mixins/BudgetSimulationMixin'
import CurrencyInput from '@/components/common/CurrencyInput'
import PercentageInput from '@/components/common/PercentageInput'
import InfoModal from '@/components/modals/InfoModal'

const isDateValid = getter => function (value) {
  if (!/\d{4}-\d{2}/.test(value)) {
    return false
  }
  const month = Number(value.substr(5))
  if (month > 12 || month === 0) {
    return false
  }
  return true
}

export default {
  name: 'PerformanceAccountReportV2BudgetSimulationView',
  components: { ToggleBudgetsDropdown, CurrencyInput, PercentageInput, InfoModal },
  mixins: [BudgetSimulationMixin],
  props: {
    isLoaded: { type: Boolean, default: false },
    showTaxField: { type: Boolean, default: false },
    account: {
      type: Object,
      default: () => {
        return {
          title: '',
          type: ''
        }
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.performance.account.simulation.',
      showChart: 'simulation-performance-total-amount',
      oSimulationParams: {
        phase1: {
          amountPerMonth: 0,
          annualIncrease: 2,
          start_yearmonth: moment().format('YYYY-MM'),
          tax: 0
        },
        phase2: {
          amountPerMonth: 0,
          annualIncrease: 2,
          start_yearmonth: moment().add(10, 'y').format('YYYY-MM'),
          tax: 0
        },
        phase3: {
          amountPerMonth: 0,
          annualIncrease: 2,
          start_yearmonth: moment().add(20, 'y').format('YYYY-MM'),
          tax: 0
        },
        end_yearmonth: null,
        taxType: null
      }
    }
  },
  validations: {
    oSimulationParams: {
      end_yearmonth: { required, isDateValid: isDateValid() },
      phase1: {
        amountPerMonth: { required },
        start_yearmonth: { required, isDateValid: isDateValid() },
        annualIncrease: { required },
        tax: { required }
      },
      phase2: {
        amountPerMonth: { required },
        start_yearmonth: { required, isDateValid: isDateValid() },
        annualIncrease: { required },
        tax: { required }
      },
      phase3: {
        amountPerMonth: { required },
        start_yearmonth: { required, isDateValid: isDateValid() },
        annualIncrease: { required },
        tax: { required }
      }
    },
    presetTitle: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(100)
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    inputValidationClasses () {
      const classes = {
        phase1: {
          amountPerMonth: {
            'is-invalid': this.$v.oSimulationParams.phase1.amountPerMonth.$dirty && this.$v.oSimulationParams.phase1.amountPerMonth.$error,
            'is-valid': this.$v.oSimulationParams.phase1.amountPerMonth.$dirty && !this.$v.oSimulationParams.phase1.amountPerMonth.$error
          },
          annualIncrease: {
            'is-invalid': this.$v.oSimulationParams.phase1.annualIncrease.$dirty && this.$v.oSimulationParams.phase1.annualIncrease.$error,
            'is-valid': this.$v.oSimulationParams.phase1.annualIncrease.$dirty && !this.$v.oSimulationParams.phase1.annualIncrease.$error
          },
          tax: {
            'is-invalid': this.$v.oSimulationParams.phase1.tax.$dirty && this.$v.oSimulationParams.phase1.tax.$error,
            'is-valid': this.$v.oSimulationParams.phase1.tax.$dirty && !this.$v.oSimulationParams.phase1.tax.$error
          }
        },
        phase2: {
          amountPerMonth: {
            'is-invalid': this.$v.oSimulationParams.phase2.amountPerMonth.$dirty && this.$v.oSimulationParams.phase2.amountPerMonth.$error,
            'is-valid': this.$v.oSimulationParams.phase2.amountPerMonth.$dirty && !this.$v.oSimulationParams.phase2.amountPerMonth.$error
          },
          annualIncrease: {
            'is-invalid': this.$v.oSimulationParams.phase1.annualIncrease.$dirty && this.$v.oSimulationParams.phase1.annualIncrease.$error,
            'is-valid': this.$v.oSimulationParams.phase1.annualIncrease.$dirty && !this.$v.oSimulationParams.phase1.annualIncrease.$error
          },
          tax: {
            'is-invalid': this.$v.oSimulationParams.phase2.tax.$dirty && this.$v.oSimulationParams.phase2.tax.$error,
            'is-valid': this.$v.oSimulationParams.phase2.tax.$dirty && !this.$v.oSimulationParams.phase2.tax.$error
          }
        },
        phase3: {
          amountPerMonth: {
            'is-invalid': this.$v.oSimulationParams.phase3.amountPerMonth.$dirty && this.$v.oSimulationParams.phase3.amountPerMonth.$error,
            'is-valid': this.$v.oSimulationParams.phase3.amountPerMonth.$dirty && !this.$v.oSimulationParams.phase3.amountPerMonth.$error
          },
          annualIncrease: {
            'is-invalid': this.$v.oSimulationParams.phase1.annualIncrease.$dirty && this.$v.oSimulationParams.phase1.annualIncrease.$error,
            'is-valid': this.$v.oSimulationParams.phase1.annualIncrease.$dirty && !this.$v.oSimulationParams.phase1.annualIncrease.$error
          },
          tax: {
            'is-invalid': this.$v.oSimulationParams.phase3.tax.$dirty && this.$v.oSimulationParams.phase3.tax.$error,
            'is-valid': this.$v.oSimulationParams.phase3.tax.$dirty && !this.$v.oSimulationParams.phase3.tax.$error
          }
        }
      }

      return classes
    },
    phase1AnnualIncreaseInvalidFeedback () {
      if (this.$v.oSimulationParams.phase1.annualIncrease.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    phase2AnnualIncreaseInvalidFeedback () {
      if (this.$v.oSimulationParams.phase2.annualIncrease.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    phase3AnnualIncreaseInvalidFeedback () {
      if (this.$v.oSimulationParams.phase3.annualIncrease.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    phase1TaxInvalidFeedback () {
      if (this.$v.oSimulationParams.phase1.tax.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    phase2TaxInvalidFeedback () {
      if (this.$v.oSimulationParams.phase2.tax.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    phase3TaxInvalidFeedback () {
      if (this.$v.oSimulationParams.phase2.tax.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    }
  },
  methods: {
    simulate () {
      this.$v.oSimulationParams.$touch()
      if (this.$v.oSimulationParams.$invalid) {
        return false
      }

      const oPeriodTemplateData = {
        amount: 0,
        increase: 0,
        tax: 0,
        total: 0
      }
      const iStartYear = moment(this.oSimulationParams.phase1.start_yearmonth + '-15', 'YYYY-MM-DD').format('YYYY')
      const iEndYear = moment(this.oSimulationParams.end_yearmonth + '-15', 'YYYY-MM-DD').format('YYYY')
      let iStartMonth = moment(this.oSimulationParams.phase1.start_yearmonth + '-15', 'YYYY-MM-DD').format('M') - 1

      this.aOutputData = { annual_data: [], monthly_data: [] }

      // Year
      let iTmpAmount = 0
      let iAccIncrease = 0
      let iTmpIncrease = 0

      for (let iYear = iStartYear; iYear <= iEndYear; iYear++) {
        const oYearPeriod = { ...oPeriodTemplateData }

        oYearPeriod.year = iYear
        oYearPeriod.months = []

        // Month
        for (let iMonth = iStartMonth; iMonth <= 11; iMonth++) {
          // Identify phase
          const sPhase = this.identifyPhase(iYear, iMonth)

          iTmpAmount = this.oSimulationParams[sPhase].amountPerMonth + iAccIncrease

          // Month
          const oMonthPeriod = { ...oPeriodTemplateData }
          oMonthPeriod.year = iYear
          oMonthPeriod.month = iMonth + 1
          oMonthPeriod.amount = iTmpAmount
          oMonthPeriod.increase = Math.round((oMonthPeriod.amount) * this.oSimulationParams[sPhase].annualIncrease / 100)
          const amountAndIncrease = oMonthPeriod.amount + oMonthPeriod.increase
          oMonthPeriod.tax = amountAndIncrease * (this.oSimulationParams[sPhase].tax / 100)
          oMonthPeriod.total = amountAndIncrease - oMonthPeriod.tax

          iTmpIncrease = oMonthPeriod.increase

          // Year
          oYearPeriod.months[iMonth] = oMonthPeriod
          oYearPeriod.amount += oMonthPeriod.amount
          oYearPeriod.increase += oMonthPeriod.increase
          oYearPeriod.total += oMonthPeriod.total
          oYearPeriod.tax += oMonthPeriod.tax

          this.aOutputData.monthly_data.push(oMonthPeriod)
        }
        iStartMonth = 0
        iAccIncrease += iTmpIncrease

        this.aOutputData.annual_data.push(oYearPeriod)
      }

      this.$emit('simulation-data', { data: this.aOutputData })
    },
    exportMonthlyValues () {
      let sData = this.$t(this.translationPath + 'params.export_csv_' + this.account.type + '_column_names') + '\n'

      for (const i in this.aOutputData.monthly_data) {
        const oMonthData = this.aOutputData.monthly_data[i]
        sData += oMonthData.year + ';'
        sData += oMonthData.month + ';'
        sData += oMonthData.amount + ';'
        sData += oMonthData.increase + ';'
        if (this.account.type === 'income') {
          sData += oMonthData.tax + ';'
        }
        sData += oMonthData.total
        sData += '\n'
      }

      const url = window.URL.createObjectURL(new Blob(['\ufeff', sData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'spirecta-simulation-' + this.account.type + '-' + this.account.id + '.csv')
      document.body.appendChild(link)
      link.click()
    },
    tableColumns (accountType, periodTable) {
      const cols = []
      cols.push({
        key: 'year',
        label: this.$t(this.translationPath + 'table.year'),
        sortable: false,
        class: 'text-left td-year'
      })

      if (periodTable === 'monthly') {
        cols.push({
          key: 'month',
          label: this.$t(this.translationPath + 'table.month'),
          sortable: false,
          class: 'text-left td-month'
        })
      }

      cols.push({
        key: 'amount',
        label: this.$t(this.translationPath + 'table.amount'),
        sortable: false,
        class: 'text-right td-amount',
        formatter: this.amountFormatter
      })
      cols.push({
        key: 'increase',
        label: this.$t(this.translationPath + 'table.increase'),
        sortable: false,
        class: 'text-right td-increase',
        formatter: this.amountFormatter
      })

      if (accountType === 'income') {
        cols.push({
          key: 'tax',
          label: this.$t(this.translationPath + 'table.tax_amount'),
          sortable: false,
          class: 'text-right td-tax_amount',
          formatter: this.amountFormatter
        })
      }

      cols.push({
        key: 'total',
        label: this.$t(this.translationPath + 'table.total'),
        sortable: false,
        class: 'text-right td-total',
        formatter: this.amountFormatter
      })

      return cols
    },
    setupTaxDefaults () {
      if (this.oSimulationParams.phase1.tax === null) {
        switch (this.account.type) {
          case 'income':
            this.oSimulationParams.phase1.tax = 0
            this.oSimulationParams.phase2.tax = 0
            this.oSimulationParams.phase3.tax = 0
            break
          default:
            this.oSimulationParams.phase1.tax = 0
            this.oSimulationParams.phase2.tax = 0
            this.oSimulationParams.phase3.tax = 0
            break
        }
      }
    }
  },
  created () {
    if (this.$route.query.budget) {
      this.budgetId = Number(this.$route.query.budget)
    } else {
      this.budgetId = this.currentCOA.default_budget_id
    }

    this.setupFormDefaultsOnce()
    this.setupTaxDefaults()
    this.loadBudgets()
    this.loadPresets()
    this.loadNotes()
  },
  watch: {
    account: {
      deep: true,
      handler () {
        this.setupTaxDefaults()
      }
    },
    'meta.avgAmount' () {
      this.oSimulationParams.phase1.amountPerMonth = this.meta.avgAmount
      this.oSimulationParams.phase2.amountPerMonth = this.meta.avgAmount
      this.oSimulationParams.phase3.amountPerMonth = this.meta.avgAmount
    }
  }
}
</script>

<style lang="scss">
.component-performance-account-report-budget-simulation-view {
  .simulation-result-table {
    th {
      border-top: none;
    }
    td {
      font-size: 95%;
    }
  }
  #import_textarea {
    height: 400px;
  }
  .budgets-select {
    width: auto;
  }
}
</style>
