<template>
  <div class="component-balance-account-report-budget-simulation-view">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="12" md="3" lg="4" xl="6">
                  <h2>{{ $t(translationPath + 'simulation_settings',{title: account.title }) }}</h2>
                </b-col>
                <b-col class="text-right" cols="12" md="9" lg="8" xl="6">
                  <!-- Toggle simulation charts -->
                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( translationPath + 'charts.'+showChart)"
                              variant='outline-secondary'
                              class="float-right mr-3 mb-2 spirecta-toggle-wrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('simulation-amortization')" v-bind:class="{ active: showChart === 'simulation-amortization' }">{{$t( translationPath + 'charts.simulation-amortization')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('simulation-interest')" v-bind:class="{ active: showChart === 'simulation-interest' }">{{$t( translationPath + 'charts.simulation-interest')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('simulation-outgoing-balance')" v-bind:class="{ active: showChart === 'simulation-outgoing-balance' }">{{$t( translationPath + 'charts.simulation-outgoing-balance')}}</b-dropdown-item>
                  </b-dropdown>
                  <toggle-budgets-dropdown ref="BudgetDropdown" :budgets="budgets" :initial-budget-id="budgetId" @change="onBudgetChange" />
                </b-col>
              </b-row>
            </template>

            <b-row no-gutters>
              <b-col cols="8">
                <div v-html="$t(translationPath + 'simulation_settings_description')" class="pt-2 pb-3"></div>
              </b-col>
            </b-row>

            <b-row no-gutters>
              <b-col cols="12">
                <b-card no-body class="white-tabs-card">
                  <b-tabs class="white-tabs" v-model="tabIndexSimulation">
                    <b-tab :title="$t(translationPath + 'tab_simulate')">

                      <!-- START: SIMULATE FORM -->
                      <b-form class="pt-4">
                        <!-- starting_amount -->
                        <b-form-group
                          label-for="set_values_for"
                          label-cols-lg="3"
                          content-cols-lg="3"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.starting_amount')"
                          :invalid-feedback="startingAmountInvalidFeedback"
                        >
                          <currency-input
                            ref="CurrencyInputStartingAmount"
                            id="starting_amount"
                            v-model="oSimulationParams.startingAmount"
                            :class="inputValidationClasses.startingAmount"
                            :precision="0"
                          />
                        </b-form-group>

                        <!-- years -->
                        <b-form-group
                          label-for="end_yearmonth"
                          label-cols-lg="3"
                          content-cols-lg="3"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.end_yearmonth')"
                          :description="$t(translationPath + 'params.period_years_description')"
                          :invalid-feedback="endYearmonthInvalidFeedback"
                        >
                          <b-form-input
                            id="start_year_phase1"
                            v-model="oSimulationParams.end_yearmonth"
                            class="form-control text-left text-md-right"
                            :state="$v.oSimulationParams.end_yearmonth.$dirty ? !$v.oSimulationParams.end_yearmonth.$invalid : null"
                          />
                        </b-form-group>

                        <b-row no-gutters>
                          <b-col cols="3"></b-col>
                          <b-col cols="3" class="px-2 pb-2">{{$t(translationPath + 'params.phase01')}}</b-col>
                          <b-col cols="3" class="px-2 pb-2">{{$t(translationPath + 'params.phase02')}}</b-col>
                          <b-col cols="3" class="px-2 pb-2">{{$t(translationPath + 'params.phase03')}}</b-col>
                        </b-row>

                        <!-- *************************************************
                              Period years
                        ************************************************* -->
                        <b-row no-gutters>
                          <b-col cols="3" class="px-2">
                            {{$t(translationPath + 'params.start_yearmonth')}}
                          </b-col>
                          <b-col cols="3">
                            <b-form-group
                              label-for="start_year_phase1"
                              label-class="pr-4 text-black"
                              :description="$t(translationPath + 'params.start_yearmonth_description', {start_period: startOfMonthFromYearMonthString(oSimulationParams.phase1.start_yearmonth), end_period: subtractOneMonthFromYearMonthString(oSimulationParams.phase2.start_yearmonth)})"
                              :invalid-feedback="phase1StartYearMonthInvalidFeedback"
                            >
                              <b-form-input
                                id="start_year_phase1"
                                v-model="oSimulationParams.phase1.start_yearmonth"
                                class="form-control text-left"
                                :state="$v.oSimulationParams.phase1.start_yearmonth.$dirty ? !$v.oSimulationParams.phase1.start_yearmonth.$invalid : null"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="start_year_phase2"
                              label-class="pr-4 text-black"
                              :description="$t(translationPath + 'params.start_yearmonth_description', {start_period: startOfMonthFromYearMonthString(oSimulationParams.phase2.start_yearmonth), end_period: subtractOneMonthFromYearMonthString(oSimulationParams.phase3.start_yearmonth)})"
                              :invalid-feedback="phase2StartYearMonthInvalidFeedback"
                            >
                              <b-form-input
                                id="start_year_phase1"
                                v-model="oSimulationParams.phase2.start_yearmonth"
                                class="form-control text-left"
                                :state="$v.oSimulationParams.phase2.start_yearmonth.$dirty ? !$v.oSimulationParams.phase2.start_yearmonth.$invalid : null"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="start_year_phase2"
                              label-class="pr-4 text-black"
                              :description="$t(translationPath + 'params.start_yearmonth_description', {start_period: startOfMonthFromYearMonthString(oSimulationParams.phase3.start_yearmonth), end_period: endOfMonthFromYearMonthString(oSimulationParams.end_yearmonth)})"
                              :invalid-feedback="phase3StartYearMonthInvalidFeedback"
                            >
                              <b-form-input
                                id="start_year_phase1"
                                v-model="oSimulationParams.phase3.start_yearmonth"
                                class="form-control text-left"
                                :state="$v.oSimulationParams.phase3.start_yearmonth.$dirty ? !$v.oSimulationParams.phase3.start_yearmonth.$invalid : null"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <!-- *************************************************
                              Amount per month
                        ************************************************* -->
                        <b-row no-gutters>
                          <b-col cols="3" class="px-2">
                            {{$t(translationPath + 'params.amortization_per_month')}}
                          </b-col>
                          <b-col cols="3">
                            <b-form-group
                              label-for="amount_per_month_phase1"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase1AmountPerMonthInvalidFeedback"
                            >
                              <currency-input
                                ref="CurrencyInputPhase1AmountPerMonth"
                                id="amount_per_month_phase1"
                                v-model="oSimulationParams.phase1.amountPerMonth"
                                :class="inputValidationClasses.phase1.amountPerMonth"
                                :precision="0"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="amount_per_month_phase2"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase2AmountPerMonthInvalidFeedback"
                            >
                              <currency-input
                                ref="CurrencyInputPhase2AmountPerMonth"
                                id="amount_per_month_phase2"
                                v-model="oSimulationParams.phase2.amountPerMonth"
                                :class="inputValidationClasses.phase2.amountPerMonth"
                                :precision="0"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="amount_per_month_phase3"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase3AmountPerMonthInvalidFeedback"
                            >
                              <currency-input
                                ref="CurrencyInputPhase3AmountPerMonth"
                                id="amount_per_month_phase3"
                                v-model="oSimulationParams.phase3.amountPerMonth"
                                :class="inputValidationClasses.phase3.amountPerMonth"
                                :precision="0"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <!-- *************************************************
                              Expected annual return
                        ************************************************* -->
                        <b-row no-gutters>
                          <b-col cols="3" class="px-2">
                            {{$t(translationPath + 'params.expected_annual_return')}}
                          </b-col>
                          <b-col cols="3">
                            <b-form-group
                              label-for="amount_per_month_phase1"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase1ExpectedAnnualReturnInvalidFeedback"
                            >
                              <percentage-input
                                id="expected_annual_return_phase1"
                                v-model="oSimulationParams.phase1.expectedAnnualReturn"
                                :class="inputValidationClasses.phase1.expectedAnnualReturn"
                                :precision="2"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="amount_per_month_phase2"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase2ExpectedAnnualReturnInvalidFeedback"
                            >
                              <percentage-input
                                id="expected_annual_return_phase2"
                                v-model="oSimulationParams.phase2.expectedAnnualReturn"
                                :class="inputValidationClasses.phase2.expectedAnnualReturn"
                                :precision="2"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="3" class="px-2">
                            <b-form-group
                              label-for="amount_per_month_phase3"
                              label-class="pr-4 text-black"
                              :label="''"
                              :invalid-feedback="phase3ExpectedAnnualReturnInvalidFeedback"
                            >
                              <percentage-input
                                id="expected_annual_return_phase3"
                                v-model="oSimulationParams.phase3.expectedAnnualReturn"
                                :class="inputValidationClasses.phase3.expectedAnnualReturn"
                                :precision="2"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <!-- PRESETS -->
                        <b-form-group
                          label-for=""
                          label-cols-lg="3"
                          content-cols-lg="9"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.presets')"
                        >
                          <b-row no-gutters>
                            <b-col cols="12" lg="4" class="pr-1">
                              <b-form-select
                                id="presets"
                                class="form-control text-left"
                                v-model="selectedPresetId"
                                :options="presetOptions"
                                @change="selectPreset"
                              />
                            </b-col>
                            <b-col cols="12" lg="8" v-if="Number.isInteger(this.selectedPresetId)">
                              <button class="btn btn-sm btn-primary mt-2 ml-lg-2" @click.prevent="savePreset">{{ $t('common.save' )}}</button>
                            </b-col>
                          </b-row>
                        </b-form-group>

                        <template v-if="this.selectedPresetId === 'new'">
                          <b-form-group
                            label-for="preset_title"
                            label-cols-lg="3"
                            content-cols-lg="9"
                            label-class="pr-4 text-black px-2"
                            :label="$t(translationPath + 'lbl_save_preset_as')"
                            :invalid-feedback="presetTitleInvalidFeedback"
                          >
                            <b-row no-gutters>
                              <b-col cols="12" lg="4" class="pr-1">
                                <b-form-input
                                  id="preset_title"
                                  class="form-control"
                                  v-model="presetTitle"
                                  :state="$v.presetTitle.$dirty ? !$v.presetTitle.$invalid : null"
                                />
                              </b-col>
                              <b-col cols="12" lg="8">
                                <button class="btn btn-sm btn-primary mt-2 ml-lg-2" @click.prevent="savePreset">{{ $t('common.save' )}}</button>
                              </b-col>
                            </b-row>
                          </b-form-group>
                        </template>

                        <!-- SIMULATE AND SAVE DATA BTNs -->
                        <b-row no-gutters>
                          <b-col cols="12" class="text-left pt-2">
                            <button class="btn btn-sm btn-primary mr-2" @click.prevent="simulateLiability" prev>{{$t(translationPath + 'params.action_simulate')}}</button>
                            <button v-if="budgets.length && aOutputData.monthly_data.length" class="btn btn-sm btn-primary" @click.prevent="saveMonthlyData" prev>{{$t(translationPath + 'params.action_save_monthly_data')}}</button>
                          </b-col>
                        </b-row>

                      </b-form>
                      <!-- END: SIMULATE FORM -->
                    </b-tab>
                    <b-tab :title="$t(translationPath + 'tab_upload_csv')">

                      <b-row no-gutters>
                        <b-col cols="8">
                          <div v-html="$t(translationPath + 'tab_upload_csv_description')" class="pt-2"></div>
                        </b-col>
                      </b-row>

                      <b-form>
                        <b-form-group
                          label-for="import_textarea"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.import_textarea')"
                        >
                          <b-form-textarea
                            id="import_textarea"
                            v-model="oImportData.textarea"
                            class="form-control text-left"
                            :class="oImportData.textAreaValidationClass"
                            :placeholder="$t(translationPath + 'params.import_liability_textarea_placeholder')"
                            @input="oImportData.textAreaValidationClass = ''"
                            >
                          </b-form-textarea>
                        </b-form-group>
                      </b-form>

                      <!-- PARSE BTN -->
                      <b-row no-gutters>
                        <b-col cols="3" class="text-left">
                          <button class="btn btn-sm btn-primary" @click.prevent="parsePastedValues" prev>{{$t(translationPath + 'params.action_import')}}</button>
                        </b-col>
                      </b-row>

                    </b-tab>
                    <b-tab :title="$t(translationPath + 'tab_notes')">
                      <b-row no-gutters>
                        <b-col cols="8">
                          <div v-html="$t(translationPath + 'tab_notes_description')" class="pt-2 mb-3"></div>
                        </b-col>
                      </b-row>

                      <b-form>
                        <b-form-group
                          label-for="import_textarea"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.notes')"
                        >
                          <b-form-textarea
                            id="import_textarea"
                            v-model="notes"
                            class="form-control text-left"
                          />
                        </b-form-group>
                      </b-form>

                      <b-row no-gutters>
                        <b-col cols="3" class="text-left">
                          <button class="btn btn-sm btn-primary" @click.prevent="updateNotes" prev>{{$t(translationPath + 'params.action_update_notes')}}</button>
                        </b-col>
                      </b-row>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>
            </b-row>

          </b-card>

          <b-card class="main-gradient-content-card borderlight mt-3 pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'simulation_values', { budget: budgetTitle }) }}</h2>
                </b-col>
                <b-col cols="4" class="text-right">
                  <button class="btn btn-sm btn-outline-secondary" @click.prevent="exportMonthlyValues()">{{$t(translationPath + 'export_transactions' )}}</button>
                </b-col>
              </b-row>

              <b-row no-gutters>
                <b-col cols="8">
                  <div v-html="$t(translationPath + 'simulation_values_description')" class="pt-2"></div>
                </b-col>
              </b-row>

              <b-row no-gutters>
                <b-col cols="12">
                  <b-card no-body class="white-tabs-card">
                    <b-tabs class="white-tabs" v-model="tabIndexData">
                      <b-tab :title="$t(translationPath + 'annual_data')">
                        <!-- ANNUAL DATA TABLE -->
                        <b-table
                          class="spirecta-simple-table simulation-result-table mb-0"
                          show-empty
                          hover
                          responsive
                          striped
                          stacked="md"
                          :items="aOutputData.annual_data"
                          :fields="aTableColumns('annual')"
                          :busy="!isLoaded || isLoadingTable"
                        >
                          <template v-slot:table-busy>
                            <loader />
                          </template>
                        </b-table>
                      </b-tab>
                      <b-tab :title="$t(translationPath + 'monthly_data')">
                        <!-- MONTHLY DATA TABLE -->
                        <b-table
                          class="spirecta-simple-table simulation-result-table mb-0"
                          show-empty
                          hover
                          responsive
                          striped
                          stacked="md"
                          :items="aOutputData.monthly_data"
                          :fields="aTableColumns('monthly')"
                          :busy="!isLoaded || isLoadingTable"
                          :per-page="oPaginationOptions.perMonthTable.iRowsPerPage"
                          :current-page="oPaginationOptions.perMonthTable.iCurrentPage"
                          :key="renderKey"
                        >
                          <template v-slot:table-busy>
                            <loader />
                          </template>

                          <template v-slot:cell(total_value_change)="row">
                            <template v-if="!row.item.edit_mode">
                              {{ row.value }}
                            </template>
                            <template v-else>
                              <currency-input
                                :ref="'CurrencyInputTotalValueChange' + row.item.id"
                                id="total_value_change"
                                v-model="row.item.total_value_change"
                                :precision="0"
                              />
                            </template>
                          </template>

                          <template v-slot:cell(added_amount)="row">
                            <template v-if="!row.item.edit_mode">
                              {{ row.value }}
                            </template>
                            <template v-else>
                              <currency-input
                                :ref="'CurrencyInputAddedAmount' + row.item.id"
                                id="added_amount"
                                v-model="row.item.added_amount"
                                :precision="0"
                              />
                            </template>
                          </template>

                          <template v-slot:cell(value_before_tax)="row">
                            <template v-if="!row.item.edit_mode">
                              {{ row.value }}
                            </template>
                            <template v-else>
                              <currency-input
                                :ref="'CurrencyInputValueBeforeTax' + row.item.id"
                                id="value_before_tax"
                                v-model="row.item.value_before_tax"
                                :precision="0"
                              />
                            </template>
                          </template>

                          <template v-slot:cell(tax_amount)="row">
                            <template v-if="!row.item.edit_mode">
                              {{ row.value }}
                            </template>
                            <template v-else>
                              <currency-input
                                :ref="'CurrencyInputTaxAmount' + row.item.id"
                                id="tax_amount"
                                v-model="row.item.tax_amount"
                                :precision="0"
                              />
                            </template>
                          </template>

                          <template v-slot:cell(outgoing_balance)="row">
                            <template v-if="!row.item.edit_mode">
                              {{ row.value }}
                            </template>
                            <template v-else>
                              <currency-input
                                :ref="'CurrencyInputOutgoingBalance' + row.item.id"
                                id="outgoing_balance"
                                v-model="row.item.outgoing_balance"
                                :precision="0"
                              />
                            </template>
                          </template>

                          <template v-slot:cell(incoming_balance)="row">
                            <template v-if="!row.item.edit_mode">
                              {{ row.value }}
                            </template>
                            <template v-else>
                              <currency-input
                                :ref="'CurrencyInputIncomingBalance' + row.item.id"
                                id="incoming_balance"
                                v-model="row.item.incoming_balance"
                                :precision="0"
                              />
                            </template>
                          </template>

                          <template v-slot:cell(actions)="row">
                            <template v-if="!row.item.edit_mode">
                              <b-button variant="outline-danger" class="btn-sm" @click="onMonthValueEditClick(row.item)">{{ $t('common.edit') }}</b-button>
                            </template>
                            <template v-else>
                              <b-button variant="outline-primary" class="btn-sm mt-2" @click="onMonthValueSaveClick(row.item)">{{ $t('common.save') }}</b-button>
                            </template>
                          </template>
                        </b-table>
                        <b-pagination
                          v-model="oPaginationOptions.perMonthTable.iCurrentPage"
                          :total-rows="aOutputData.monthly_data.length"
                          :per-page="oPaginationOptions.perMonthTable.iRowsPerPage"
                          aria-controls="my-table"
                        />
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </b-col>
              </b-row>

            </template>

          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <excel-data-error-modal ref="ExcelParseErrorModal" :errors="aLastExcelParseErrors" />

    <info-modal
      ref="NoSavedDataModal"
      :title="$t(translationPath + 'no_saved_data_modal_title')"
      :message="$t(translationPath + 'no_saved_data_modal_text', { account_title: account.title, budget_title: budgetTitle })"
      variant="warning"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CurrencyInput from '@/components/common/CurrencyInput'
import PercentageInput from '@/components/common/PercentageInput'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import ExcelDataErrorModal from '../inc/ExcelDataErrorModal'
import InfoModal from '@/components/modals/InfoModal'
import ToggleBudgetsDropdown from '@/components/common/ToggleBudgetsDropdown'
import BudgetSimulationMixin from '@/mixins/BudgetSimulationMixin'

const isDateValid = getter => function (value) {
  if (!/\d{4}-\d{2}/.test(value)) {
    return false
  }
  const month = Number(value.substr(5))
  if (month > 12 || month === 0) {
    return false
  }
  return true
}

export default {
  name: 'BalanceAccountReportBudgetSimulationLiabilityView',
  mixins: [BudgetSimulationMixin],
  components: {
    CurrencyInput,
    PercentageInput,
    ExcelDataErrorModal,
    InfoModal,
    ToggleBudgetsDropdown
  },
  props: {
    activeTab: { type: String, default: '' },
    isLoaded: { type: Boolean, default: false },
    incomingBalance: { type: Number, default: 10000 },
    account: {
      type: Object,
      default: () => {
        return {
          title: ''
        }
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.balance.account.budget_simulation.',
      oSimulationParams: {
        phase1: {
          amountPerMonth: 0,
          expectedAnnualReturn: 10,
          start_yearmonth: moment().format('YYYY-MM')
        },
        phase2: {
          amountPerMonth: 0,
          expectedAnnualReturn: 10,
          start_yearmonth: '2030-01'
        },
        phase3: {
          amountPerMonth: 0,
          expectedAnnualReturn: 6,
          start_yearmonth: '2040-01'
        },
        startingAmount: 10000,
        end_yearmonth: null,
        taxType: null
      }
    }
  },
  validations: {
    oSimulationParams: {
      startingAmount: { required },
      end_yearmonth: { required, isDateValid: isDateValid() },
      phase1: {
        amountPerMonth: { required },
        start_yearmonth: { required, isDateValid: isDateValid() },
        expectedAnnualReturn: { required }
      },
      phase2: {
        amountPerMonth: { required },
        start_yearmonth: { required, isDateValid: isDateValid() },
        expectedAnnualReturn: { required }
      },
      phase3: {
        amountPerMonth: { required },
        start_yearmonth: { required, isDateValid: isDateValid() },
        expectedAnnualReturn: { required }
      }
    },
    presetTitle: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(100)
    }
  },
  created () {
    if (this.$route.query.budget) {
      this.budgetId = Number(this.$route.query.budget)
    } else {
      this.budgetId = this.currentCOA.default_budget_id
    }

    this.setupFormDefaultsOnce()
    this.loadBudgets()
    this.loadPresets()
    this.loadNotes()
    this.oSimulationParams.startingAmount = this.incomingBalance
    const expectedAnnualReturn = parseFloat(this.account.expected_annual_return)
    this.oSimulationParams.phase1.expectedAnnualReturn = expectedAnnualReturn || 5
    this.oSimulationParams.phase2.expectedAnnualReturn = expectedAnnualReturn || 5
    this.oSimulationParams.phase3.expectedAnnualReturn = expectedAnnualReturn || 5
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    inputValidationClasses () {
      const classes = {
        startingAmount: {
          'is-invalid': this.$v.oSimulationParams.startingAmount.$dirty && this.$v.oSimulationParams.startingAmount.$error,
          'is-valid': this.$v.oSimulationParams.startingAmount.$dirty && !this.$v.oSimulationParams.startingAmount.$error
        },
        phase1: {
          amountPerMonth: {
            'is-invalid': this.$v.oSimulationParams.phase1.amountPerMonth.$dirty && this.$v.oSimulationParams.phase1.amountPerMonth.$error,
            'is-valid': this.$v.oSimulationParams.phase1.amountPerMonth.$dirty && !this.$v.oSimulationParams.phase1.amountPerMonth.$error
          },
          expectedAnnualReturn: {
            'is-invalid': this.$v.oSimulationParams.phase1.expectedAnnualReturn.$dirty && this.$v.oSimulationParams.phase1.expectedAnnualReturn.$error,
            'is-valid': this.$v.oSimulationParams.phase1.expectedAnnualReturn.$dirty && !this.$v.oSimulationParams.phase1.expectedAnnualReturn.$error
          }
        },
        phase2: {
          amountPerMonth: {
            'is-invalid': this.$v.oSimulationParams.phase2.amountPerMonth.$dirty && this.$v.oSimulationParams.phase2.amountPerMonth.$error,
            'is-valid': this.$v.oSimulationParams.phase2.amountPerMonth.$dirty && !this.$v.oSimulationParams.phase2.amountPerMonth.$error
          },
          expectedAnnualReturn: {
            'is-invalid': this.$v.oSimulationParams.phase1.expectedAnnualReturn.$dirty && this.$v.oSimulationParams.phase1.expectedAnnualReturn.$error,
            'is-valid': this.$v.oSimulationParams.phase1.expectedAnnualReturn.$dirty && !this.$v.oSimulationParams.phase1.expectedAnnualReturn.$error
          }
        },
        phase3: {
          amountPerMonth: {
            'is-invalid': this.$v.oSimulationParams.phase3.amountPerMonth.$dirty && this.$v.oSimulationParams.phase3.amountPerMonth.$error,
            'is-valid': this.$v.oSimulationParams.phase3.amountPerMonth.$dirty && !this.$v.oSimulationParams.phase3.amountPerMonth.$error
          },
          expectedAnnualReturn: {
            'is-invalid': this.$v.oSimulationParams.phase1.expectedAnnualReturn.$dirty && this.$v.oSimulationParams.phase1.expectedAnnualReturn.$error,
            'is-valid': this.$v.oSimulationParams.phase1.expectedAnnualReturn.$dirty && !this.$v.oSimulationParams.phase1.expectedAnnualReturn.$error
          }
        }
      }

      return classes
    },
    startingAmountInvalidFeedback () {
      if (this.$v.oSimulationParams.startingAmount.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    phase1ExpectedAnnualReturnInvalidFeedback () {
      if (this.$v.oSimulationParams.phase1.expectedAnnualReturn.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    phase2ExpectedAnnualReturnInvalidFeedback () {
      if (this.$v.oSimulationParams.phase2.expectedAnnualReturn.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    phase3ExpectedAnnualReturnInvalidFeedback () {
      if (this.$v.oSimulationParams.phase3.expectedAnnualReturn.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    }
  },
  methods: {
    onMonthValueEditClick (item) {
      item.edit_mode = true
      this.renderKey++
    },
    onMonthValueSaveClick (item) {
      item.edit_mode = false
      this.renderKey++
    },
    aTableColumns (sTablePeriod) {
      let aColumns = [{ key: 'year', label: this.$t(this.translationPath + 'table.year'), sortable: false, class: 'text-left td-year' }]

      if (sTablePeriod === 'monthly') {
        aColumns.push({ key: 'month', label: this.$t(this.translationPath + 'table.month'), sortable: false, class: 'text-left td-month' })
      }
      aColumns = aColumns.concat([{ key: 'incoming_balance', label: this.$t(this.translationPath + 'table.incoming_balance'), sortable: false, class: 'text-right td-incoming_balance', formatter: this.amountFormatter },
        { key: 'amortization', label: this.$t(this.translationPath + 'table.amortization'), sortable: false, class: 'text-right td-amortization', formatter: this.amountFormatter },
        { key: 'interest', label: this.$t(this.translationPath + 'table.interest'), sortable: false, class: 'text-right td-amortization', formatter: this.amountFormatter },
        { key: 'outgoing_balance', label: this.$t(this.translationPath + 'table.outgoing_balance'), sortable: false, class: 'text-right td-outgoing_balance', formatter: this.amountFormatter },
        { key: 'actions', label: '' }
      ])

      return aColumns
    },
    simulateLiability () {
      this.$v.oSimulationParams.$touch()
      if (this.$v.oSimulationParams.$invalid) {
        return false
      }

      const oPeriodTemplateData = {
        incoming_balance: 0,
        amortization: 0,
        interest: 0,
        outgoing_balance: 0
      }
      const iStartYear = moment(this.oSimulationParams.phase1.start_yearmonth + '-15', 'YYYY-MM-DD').format('YYYY')
      const iEndYear = moment(this.oSimulationParams.end_yearmonth + '-15', 'YYYY-MM-DD').format('YYYY')
      let iStartMonth = moment(this.oSimulationParams.phase1.start_yearmonth + '-15', 'YYYY-MM-DD').format('M') - 1
      let iIncomingBalance = this.oSimulationParams.startingAmount

      this.aOutputData = { annual_data: [], monthly_data: [] }

      // Year
      for (let iYear = iStartYear; iYear <= iEndYear; iYear++) {
        const oYearPeriod = { ...oPeriodTemplateData }

        oYearPeriod.year = iYear
        oYearPeriod.months = []
        oYearPeriod.incoming_balance = iIncomingBalance

        for (let iMonth = iStartMonth; iMonth <= 11; iMonth++) {
          const sPhase = this.identifyPhase(iYear, iMonth)

          // Month
          const oMonthPeriod = { ...oPeriodTemplateData }
          oMonthPeriod.year = iYear
          oMonthPeriod.month = iMonth + 1
          oMonthPeriod.incoming_balance = iIncomingBalance
          oMonthPeriod.amortization = this.oSimulationParams[sPhase].amountPerMonth
          oMonthPeriod.interest = iIncomingBalance * this.getMonthlyReturnBasedOnPhase(sPhase)
          oMonthPeriod.outgoing_balance = oMonthPeriod.incoming_balance - oMonthPeriod.amortization

          // Year
          if (iStartMonth === iMonth && iStartYear === iYear) {
            oYearPeriod.incoming_balance = this.oSimulationParams.startingAmount
          } else if (iStartMonth === iMonth && iStartYear !== iYear) {
            oYearPeriod.incoming_balance = iIncomingBalance
          }
          oYearPeriod.months[iMonth] = oMonthPeriod
          oYearPeriod.amortization += oMonthPeriod.amortization
          oYearPeriod.interest += oMonthPeriod.interest
          oYearPeriod.outgoing_balance = oMonthPeriod.outgoing_balance

          this.aOutputData.monthly_data.push(oMonthPeriod)

          iIncomingBalance = oMonthPeriod.outgoing_balance
        }
        iStartMonth = 0

        this.aOutputData.annual_data.push(oYearPeriod)

        this.$emit('simulation-data', { data: this.aOutputData })
      }
    },
    getMonthlyReturnBasedOnPhase (sPhase) {
      /**
       * Since we are calculating compound interest on a monthly basis with an annual return, we need to recalculate it (and not with rate /12)
       * i_monthly = (1 + i_annual) ^ (1/12) – 1
       * see: https://calculate.onl/convert-annual-interest-rates/
       **/
      return Math.pow(1 + this.oSimulationParams[sPhase].expectedAnnualReturn / 100, (1 / 12)) - 1
    },
    exportMonthlyValues () {
      let sData = this.$t(this.translationPath + 'params.export_csv_column_names') + '\n'
      let oMonthData = null

      for (const idx in this.aOutputData.monthly_data) {
        oMonthData = this.aOutputData.monthly_data[idx]

        sData += oMonthData.year + ';'
        sData += oMonthData.month + ';'
        sData += oMonthData.incoming_balance + ';'
        sData += oMonthData.added_amount + ';'
        sData += oMonthData.total_value_change + ';'
        sData += oMonthData.value_before_tax + ';'
        sData += oMonthData.tax_amount + ';'
        sData += oMonthData.outgoing_balance
        sData += '\n'
      }

      const url = window.URL.createObjectURL(new Blob(['\ufeff', sData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'spirecta-simulation-asset-' + this.account.id + '.csv')
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>

<style lang="scss">
.component-balance-account-report-budget-simulation-view {
  .simulation-result-table {
    th {
      border-top: none;
    }
    td {
      font-size: 95%;
    }
  }
  #import_textarea{
    height: 400px;
  }
  .budgets-select {
    width: auto;
  }
}
</style>
